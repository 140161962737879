export function capitalizeFirstLetter(inputString) {
  if (inputString.length === 0) {
    return inputString;
  }
  return inputString[0].toUpperCase() + inputString.slice(1);
}

export const categoriesToSearchResults = (categories) => {
  const searchResultsArr = [];

  searchResultsArr.push({
    "title-ES": "Reportes",
    "title-EN": "Reports",
    "section-ES": "",
    "section-EN": "",
    url: "regional",
  });

  for (const key in categories) {
    const parentKeyData = {
      "title-ES": key,
      "title-EN": key === "Herramientas" ? "Tools" : key,
      "section-ES": key === "Herramientas" ? "" : "Reportes",
      "section-EN": key === "Herramientas" ? "" : "Reports",
      url: key.toLowerCase(),
    };

    searchResultsArr.push(parentKeyData);
    categories[key].forEach((element) => {
      const firstChildElement = {
        "title-ES": element["title-ES"],
        "title-EN": element["title-EN"],
        "section-ES": `${key === "Herramientas" ? "" : "Reportes > "}${
          parentKeyData["title-ES"]
        }`,
        "section-EN": `${key === "Herramientas" ? "" : "Reports > "}${
          parentKeyData["title-ES"]
        }`,
        url: `${parentKeyData.url}-${element["title-ES"].toLowerCase()}`,
      };
      searchResultsArr.push(firstChildElement);
      element.links.forEach((card) => {
        searchResultsArr.push({
          "title-ES": card["title-ES"],
          "title-EN": card["title-EN"],
          "section-ES": `${firstChildElement["section-ES"]} > ${element["title-ES"]}`,
          "section-EN": `${firstChildElement["section-EN"]} > ${element["title-EN"]}`,
          url: `${parentKeyData.url}-${element["title-ES"].toLowerCase()}`,
          index: card.index,
        });
      });
    });
  }
  return searchResultsArr;
};
