import React, { useState, useEffect } from "react";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import classes from "./NewCardForm.module.css";
import { svgIcons } from "../../icons/svg-icons";

const NewCardForm = ({
  parent,
  section,
  existingCardData,
  handleReset,
  action,
  credentials,
  cardsLength,
}) => {
  const [id, setId] = useState(null);
  const [cardData, setCardData] = useState({
    titleEs: "",
    titleEn: "",
    descriptionEs: "",
    descriptionEn: "",
    link: "",
    icon: "",
    index: cardsLength + 1,
    file: null,
    error: "",
    message: "",
  });
  let icons = Object.keys(svgIcons)
    .sort()
    .filter((icon) => svgIcons[icon].isCardIcon);

  useEffect(() => {
    if (existingCardData) {
      setCardData({
        titleEs: existingCardData.titleEs.S ?? "",
        titleEn: existingCardData.titleEn.S ?? "",
        descriptionEs: existingCardData.descriptionEs.S ?? "",
        descriptionEn: existingCardData.descriptionEn.S ?? "",
        link: existingCardData.link.S ?? "",
        icon: existingCardData.icon.S ?? "",
        index: existingCardData.index.N ?? "",
        file: null,
        error: "",
        message: "",
      });
      setId(existingCardData.id.S);
    }
  }, [existingCardData]);

  const validateFile = (file) => {
    const isValidSize = file.size <= 500 * 1024; // 250KB
    const isValidType = file.type === "image/png";

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const isValidDimensions = img.width <= 2000 && img.height <= 2000;
        if (isValidSize && isValidType && isValidDimensions) {
          resolve(true);
        } else {
          reject(
            new Error(
              isValidSize
                ? "El tamaño es inválido"
                : isValidType
                ? "El formto debe ser .png"
                : "Las dimensiones son inválidas"
            )
          );
        }
      };
      img.onerror = () => {
        reject(new Error("La imagen falló en cargar."));
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setCardData({
      ...cardData,
      [name]: files ? files[0] : value,
    });
  };

  const handleUpload = async () => {
    if (!credentials) {
      console.log("No valid credentials", credentials);
      return;
    }
    const {
      titleEs,
      titleEn,
      descriptionEs,
      descriptionEn,
      link,
      icon,
      index,
      file,
    } = cardData;

    if (!titleEs || !titleEn || !descriptionEs || !descriptionEn || !link) {
      setCardData({ ...cardData, error: "Complete los campos requeridos" });
      return;
    }

    // ADD: Handle index type

    if (!file && !icon && action === "add") {
      setCardData({
        ...cardData,
        error: "Seleccione un 'icono o imagen.",
      });
      return;
    }

    try {
      const lambda = new LambdaClient(credentials);

      if (file) {
        await validateFile(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          const base64File = file ? reader.result.split(",")[1] : null;
          try {
            const params = {
              FunctionName:
                action === "add"
                  ? "portalAddCardHandler"
                  : "portalPatchCardHandler", // hide lambda name
              Payload: JSON.stringify({
                id,
                parent,
                section,
                titleEs,
                titleEn,
                descriptionEs,
                descriptionEn,
                link,
                icon: null,
                index,
                fileContent: base64File,
              }),
            };
            await lambda.send(new InvokeCommand(params));

            // const data = JSON.parse(new TextDecoder().decode(response.Payload));
            // console.log("DATA", data);

            setCardData({
              titleEs: "",
              titleEn: "",
              descriptionEs: "",
              descriptionEn: "",
              link: "",
              icon: "",
              index: "",
              file: null,
              message: "La tarjeta se cargó correctamente.",
              error: "",
            });
          } catch (uploadError) {
            setCardData({
              ...cardData,
              error: `ERROR: ${uploadError.message}`, // Convert error to string
              message: "",
            });
          }
        };
      }

      if (!file) {
        try {
          const params = {
            FunctionName:
              action === "add"
                ? "portalAddCardHandler"
                : "portalPatchCardHandler", // replace with your Lambda function name
            Payload: JSON.stringify({
              id,
              parent,
              section,
              titleEs,
              titleEn,
              descriptionEs,
              descriptionEn,
              icon,
              link,
              index,
              fileContent: null,
            }),
          };

          await lambda.send(new InvokeCommand(params));

          // const data = JSON.parse(new TextDecoder().decode(response.Payload));
          // console.log("data", data);

          setCardData({
            ...cardData,
            message: "Card uploaded successfully",
            error: "",
          });
        } catch (uploadError) {
          setCardData({
            ...cardData,
            error: `ERROR: ${uploadError.message}`, // Convert error to string
            message: "",
          });
        }
      }
    } catch (validationError) {
      setCardData({ ...cardData, error: validationError.message, message: "" }); // Convert error to string
    }
  };
  useEffect(() => {
    if (cardData.message) {
      setTimeout(() => {
        handleReset();
      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData.message]);

  return (
    <div className={`container ${classes.container}`}>
      {!cardData.message && (
        <>
          <div
            className={classes.back__arrow}
            onClick={() => {
              handleReset();
            }}
            dangerouslySetInnerHTML={{ __html: svgIcons["go-back-arrow"].icon }}
          />
          <div className="mb-3">
            <label htmlFor="titleEs" className="form-label">
              Título en español
            </label>
            <input
              type="text"
              className={`form-control ${classes.inputField}`}
              id="titleEs"
              name="titleEs"
              value={cardData.titleEs}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="titleEn" className="form-label">
              Título en inglés
            </label>
            <input
              type="text"
              className={`form-control ${classes.inputField}`}
              id="titleEn"
              name="titleEn"
              value={cardData.titleEn}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="descriptionEs" className="form-label">
              Descripción en español
            </label>
            <input
              type="text"
              className={`form-control ${classes.inputField}`}
              id="descriptionEs"
              name="descriptionEs"
              value={cardData.descriptionEs}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="descriptionEn" className="form-label">
              Descripción en inglés
            </label>
            <input
              type="text"
              className={`form-control ${classes.inputField}`}
              id="descriptionEn"
              name="descriptionEn"
              value={cardData.descriptionEn}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="link" className="form-label">
              Link
            </label>
            <input
              type="text"
              className={`form-control ${classes.inputField}`}
              id="link"
              name="link"
              value={cardData.link}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="index" className="form-label">
              Índice
            </label>
            <select
              className={`form-select ${classes.inputField}`}
              id="index"
              name="index"
              value={cardData.index}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Seleccionar un índice
              </option>
              {[...Array(cardsLength).keys()].map((index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
              <option value={cardsLength + 1}>{cardsLength + 1}</option>
            </select>
          </div>

          {parent !== "Herramientas" && (
            <div className="mb-3">
              <label htmlFor="icon" className="form-label">
                Ícono
              </label>
              <select
                className={`form-select ${classes.inputField}`}
                id="icon"
                name="icon"
                value={cardData.icon}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Seleccionar un ícono
                </option>
                {icons.map((icon) => (
                  <option key={icon} value={icon}>
                    {icon}
                  </option>
                ))}
              </select>
            </div>
          )}
          {parent === "Herramientas" && (
            <div className="mb-3">
              <label htmlFor="file" className="form-label">
                Archivo
              </label>
              <input
                type="file"
                className={`form-control ${classes.inputField}`}
                id="file"
                accept=".png"
                name="file"
                onChange={handleInputChange}
              />
            </div>
          )}
          <button
            className={`btn btn-primary ${classes.button}`}
            onClick={handleUpload}
          >
            Enviar
          </button>
        </>
      )}

      {cardData.error && <p className="text-danger">{cardData.error}</p>}
      {cardData.message && (
        <p className={`text-success ${classes.success}`}>{cardData.message}</p>
      )}
    </div>
  );
};

export default NewCardForm;
