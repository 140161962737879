import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  position: "fixed",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0, 0, 0, 0)",
  padding: "50px",
  zIndex: 100000,
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "40vw",
  top: "30vh",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.721)",
  zIndex: 100000,
};

const BUTTON_CONTAINER_STYLES = {
  display: "flex",
  flexDirection: "row",
  marginTop: "1rem",
};

export default function Modal({
  open,
  children,
  onClose,
  onConfirm,
  isConfirmationMessage,
  hideConfirm,
  isLoadingMessage,
  hideButtons,
}) {
  if (!open) return null;

  const handleModalClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose(); // Close the modal only if the click is on the modal itself
    }
  };

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div style={MODAL_STYLES} onClick={handleModalClick}>
        {children}
        {!isConfirmationMessage && !isLoadingMessage && !hideButtons && (
          <div style={BUTTON_CONTAINER_STYLES}>
            <button onClick={onClose}>Cancelar</button>
            {!hideConfirm && <button onClick={onConfirm}>Confirmar</button>}
          </div>
        )}
      </div>
    </>,
    document.getElementById("portal")
  );
}
