import React, { useContext } from "react";
import classes from "./LanguageToggle.module.css";
import DataContext from "../store/data-context";

function LanguageToggle() {
  const dataContext = useContext(DataContext);

  const { language, setLanguage } = dataContext;

  const handleLanguageChange = () => {
    setLanguage(!language);
  };

  const labelClasses = `${classes.ball} ${language ? classes.checked : ""}`;

  return (
    <div
      className={classes["language-container"]}
      onClick={handleLanguageChange}
    >
      <div className={classes["text-container"]}>
        <div>ES</div>
        <div>EN</div>
      </div>
      <div className={labelClasses}></div>
    </div>
  );
}

export default LanguageToggle;
