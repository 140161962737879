import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Auth } from "aws-amplify";

const checkUser = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    localStorage.setItem("user", JSON.stringify(Boolean(currentUser)));

    const session = currentUser.getSignInUserSession();
    const customGroup = session.idToken.payload.custom_group;

    const isAdmin = JSON.parse(
      session.idToken.payload["custom:roles"]
    ).includes(process.env.REACT_APP_ADMIN_ROLE);

    localStorage.setItem("isAdmin", isAdmin);

    if (session !== undefined && session !== false) {
      const idToken = session.getIdToken().getJwtToken();

      // Get credentials from Cognito Identity Pool
      const credentials = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: process.env.REACT_APP_REGION ?? "",
        }),
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? "",
        logins: {
          [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
            idToken,
        },
      });

      // Apply credentials to the configuration
      const awsConfig = {
        region: process.env.REACT_APP_REGION ?? "",
        credentials,
        customGroup,
        isAdmin,
      };

      return awsConfig;
    }
  } catch (error) {
    throw error;
    // console.error("Error fetching user:", error);
  }
};

export default checkUser;
