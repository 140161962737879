import React, { useRef } from "react";
import classes from "./AlternativeCard.module.css";
import useComponentVisibility from "../hooks/useComponentVisibility";

function AlternativeCard({ data, language, delay }) {
  const cardRef = useRef(null);
  const [cardIsVisible] = useComponentVisibility({
    ref: cardRef,
    threshold: 0.5,
    delay,
    loop: false,
  });

  const openLinkInNewWindow = () => {
    window.open(data.link, "_blank");
  };

  return (
    <div
      ref={cardRef}
      className={` ${classes["card-container"]}  ${
        cardIsVisible && classes["card-visible"]
      }`}
      onClick={openLinkInNewWindow}
    >
      <div className={classes["image-container"]}>
        <img src={data.imageUrl} alt={data["title-ES"]} />
      </div>
      <div className={classes["bottom-container"]}>
        <div className={classes.title}>
          {language ? data["title-ES"] : data["title-EN"]}
        </div>
        <div className={classes["text-container"]}>
          {language ? data["subtitle-ES"] : data["subtitle-EN"]}
        </div>
      </div>
    </div>
  );
}

export default AlternativeCard;
