import React, { useState } from "react";
import classes from "./SearchComponent.module.css";
import SearchBox from "./SearchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchComponent = () => {
  const [didClick, setDidClick] = useState(false);

  const handleClick = () => {
    setDidClick((prevState) => !prevState);
  };

  return (
    <>
      <div className={classes.search__component}>
        <FontAwesomeIcon
          icon={faSearch}
          className={classes.icon}
          onClick={handleClick}
        />
      </div>
      {didClick && <SearchBox handleClick={handleClick} />}
    </>
  );
};

export default SearchComponent;
