import React from "react";
import FooterIcons from "./FooterIcons";

function BottomRow({ language }) {
  return (
    <>
      <div>© Copyright 2023 Bayer S.A.</div>
      <div>
        <FooterIcons />
      </div>
    </>
  );
}

export default BottomRow;
