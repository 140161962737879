import { useState, useEffect } from "react";

const useComponentVisibility = ({ ref, loop, threshold, delay }) => {
  // delay should delay when the component is set to visible
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [didShow, setDidShow] = useState(false); // didShow is meant to be used to cancel intro effects once components have been presented

  const handleVisibilityChange = (entries) => {
    if (entries[0].isIntersecting) {
      if (delay) {
        setTimeout(() => {
          setIsComponentVisible(true);
        }, delay);
      } else {
        setIsComponentVisible(true);
      }
    } else if (loop) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(handleVisibilityChange, {
      threshold: threshold ?? 0.5,
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    // eslint-disable-next-line
  }, [ref, threshold]);

  useEffect(() => {
    if (isComponentVisible) {
      setTimeout(() => {
        setDidShow(true);
      }, 2000);
    }
  }, [isComponentVisible]);

  return [isComponentVisible, didShow];
};

export default useComponentVisibility;
