import React from "react";
import MenuItems from "./MenuItems";

const AdminNavbar = () => {
  return (
    <ul className="admin-menus">
      <MenuItems
        items={{
          "title-ES": "Home",
          "title-EN": "Home",
          link: "/home",
          url: "home",
        }}
        key={"home"}
        depthLevel={0}
        isFirst={true}
      />

      {/* <span className={classes.spacing} />
  <ActionButton
    children="Cerrar sesión"
    onClick={() => {
      console.log("clicked from navbar");
      handleLogout();
    }}
  /> */}
    </ul>
  );
};

export default AdminNavbar;
