import React, { useState, useRef } from "react";
import emailJs from "@emailjs/browser";
import contactStyles from "./Contact.module.css";
import ActionButton from "../components/ActionButton";
import { svgIcons } from "../icons/svg-icons";
import ReactDom from "react-dom";

function Contact({ language, show, handleClose }) {
  const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_PUBLIC_KEY } =
    process.env;
  const [didSend, setDidSend] = useState(false);
  const form = useRef();
  const [formdata, setFormdata] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formdata.user_name) {
      setError(true);
      setMessage("Name is required");
    } else if (!formdata.user_email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("Subject is required");
    } else if (!formdata.message) {
      setError(true);
      setMessage("Message is required");
    } else {
      setError(false);

      setMessage(
        language ? "Tu mensaje fue enviado!" : "Your message has been sent!"
      );

      try {
        emailJs
          .sendForm(
            REACT_APP_SERVICE_ID,
            REACT_APP_TEMPLATE_ID,
            form.current,
            REACT_APP_PUBLIC_KEY
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      } catch (error) {
        setMessage(error);
      }
      setFormdata({
        user_name: "",
        user_email: "",
        subject: "",
        message: "",
      });
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className={contactStyles.alertDanger}>{message}</div>;
    } else if (!error && message) {
      setTimeout(() => {
        handleClose();
        setMessage("");
      }, 2000);
      setTimeout(() => {
        setDidSend(false);
      }, 3000);
      setDidSend(true);
      return <div className={contactStyles.alertSuccess}>{message}</div>;
    } else {
      return null;
    }
  };

  const MODAL_STYLES = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  };

  const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, .7)",
    zIndex: 1000,
  };

  const containerClassName = show
    ? contactStyles.container
    : `${contactStyles.container} ${contactStyles.hidden}`;

  // if (!show) return null;

  return ReactDom.createPortal(
    <>
      {show ? (
        <div
          style={OVERLAY_STYLES}
          onClick={() => {
            handleClose();
            setDidSend(false);
            setMessage("");
          }}
        />
      ) : null}

      <div style={MODAL_STYLES} className={containerClassName}>
        {didSend ? (
          <div className={contactStyles.sent}>{message}</div>
        ) : (
          <>
            <div
              className={contactStyles["icon-container"]}
              onClick={() => {
                handleClose();
                setDidSend(false);
                setMessage("");
              }}
            >
              <div className={contactStyles.exit}>
                <div dangerouslySetInnerHTML={{ __html: svgIcons.exit }} />
              </div>
            </div>
            <form ref={form}>
              <div className={contactStyles["first-row"]}>
                <input
                  autoComplete="off"
                  type="text"
                  className={contactStyles["text-box"]}
                  name="user_name"
                  placeholder={language ? "Nombre" : "Your name"}
                  onChange={handleChange}
                  value={formdata.user_name}
                />

                <input
                  autoComplete="off"
                  type="email"
                  className={contactStyles["text-box"]}
                  name="user_email"
                  placeholder={
                    language ? "Correo electrónico" : "Email address"
                  }
                  onChange={handleChange}
                  value={formdata.user_email}
                />
              </div>

              <div className={contactStyles["second-row"]}>
                <input
                  maxLength="78"
                  autoComplete="off"
                  type="text"
                  className={contactStyles["text-box"]}
                  name="subject"
                  placeholder={language ? "Asunto" : "Subject"}
                  onChange={handleChange}
                  value={formdata.subject}
                />

                <textarea
                  maxLength="250"
                  autoComplete="off"
                  name="message"
                  className={contactStyles["text-box"]}
                  rows="4"
                  placeholder={language ? "Mensaje..." : "Message..."}
                  onChange={handleChange}
                  value={formdata.message}
                ></textarea>
              </div>

              <ActionButton
                onClick={submitHandler}
                type="submit"
                name="submit"
                value="send"
                className={`${contactStyles.btn} ${contactStyles.btnDefault} ${contactStyles.formControl}`}
              >
                {language ? "Enviar Mensaje" : "Send Message"}
              </ActionButton>
            </form>
            {handleAlerts()}
          </>
        )}
      </div>
    </>,
    document.getElementById("portal")
  );
}

export default Contact;
