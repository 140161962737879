import React from "react";

const DataContext = React.createContext({
  categories: {},
  fullCategoriesData: null,
  sideScroll: {},
  language: {},
  isAdmin: false,
  saveCategories: () => {},
  scrollToIndex: () => {},
  clearScrollIndex: () => {},
  storeLanguage: () => {},
  updateCategories: () => {},
  handleAdmin: () => {},
});

export default DataContext;
