export const menuItems = [
  {
    "title-ES": "Home",
    "title-EN": "Home",
    url: "/",
  },
  {
    "title-ES": "Reportes",
    "title-EN": "Reports",
    url: "regional",
    submenu: [
      {
        "title-ES": "Regional",
        "title-EN": "Regional",
        url: "regional",
        submenu: [
          {
            "title-ES": "Campo",
            "title-EN": "Field",
            url: "regional-campo",
            id: "regional-campo",
          },
          {
            "title-ES": "Sitio",
            "title-EN": "Site",
            url: "regional-sitio",
            id: "regional-sitio",
          },
          {
            "title-ES": "Calidad",
            "title-EN": "QA",
            url: "regional-calidad",
            id: "regional-calidad",
          },
          {
            "title-ES": "Modelos",
            "title-EN": "Models",
            url: "regional-modelos",
            id: "regional-modelos",
          },
        ],
      },
      {
        "title-ES": "Global",
        "title-EN": "Global",
        url: "global",
        submenu: [
          {
            "title-ES": "Modelos",
            "title-EN": "Models",
            url: "global-modelos",
            id: "global-modelos",
          },
        ],
      },
    ],
  },
  {
    "title-ES": "Herramientas",
    "title-EN": "Tools",
    url: "herramientas",
    submenu: [
      {
        "title-ES": "Campo",
        "title-EN": "Field",
        url: "campo",
        id: "herramientas-campo",
      },
      {
        "title-ES": "Sitio",
        "title-EN": "Site",
        url: "herramientas-sitio",
        id: "herramientas-sitio",
      },
      {
        "title-ES": "Equipo",
        "title-EN": "Team",
        url: "herramientas-equipo",
        id: "herramientas-equipo",
      },
    ],
  },
  {
    "title-ES": "Experiencia MEU",
    "title-EN": "MEU Experience",
    link: "https://www.plantamariaeugenia.com/virtualtour/",
    url: "contact",
  },
  {
    "title-ES": "Contacto",
    "title-EN": "Contact",
    url: "contact",
  },
];
