import Home from "./routes/Home";
import MainContainer from "./auth/MainContainer";
import Login from "./views/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DataProvider from "./store/DataProvider";
import Dashboard from "./routes/dashboard/Dashboard";

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/home" element={<MainContainer />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/dashboard" element={<MainContainer />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="*" element={<p>Not found!</p>} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
