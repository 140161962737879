import { menuItems } from "../object-data/menuItems";
import MenuItems from "./MenuItems";
import LanguageToggle from "./LanguageToggle";
import SearchComponent from "./search-box/SearchComponent";
import { useContext } from "react";
import DataContext from "../store/data-context";
// import ActionButton from "./ActionButton";
// import classes from "./Navbar.module.css";

const Navbar = ({ handleLogout }) => {
  const dataContext = useContext(DataContext);
  const { isAdmin } = dataContext;
  return (
    <>
      <ul className="menus">
        <div className="routes-container">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <MenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                isFirst={true}
              />
            );
          })}
          {isAdmin && (
            <MenuItems
              items={{
                "title-ES": "Admin",
                "title-EN": "Admin",
                link: "/dashboard",
                url: "admin",
              }}
              key={"admin"}
              depthLevel={0}
              isFirst={true}
            />
          )}
        </div>
        <SearchComponent />
        <LanguageToggle />

        {/* <span className={classes.spacing} />
        <ActionButton
          children="Cerrar sesión"
          onClick={() => {
            console.log("clicked from navbar");
            handleLogout();
          }}
        /> */}
      </ul>
    </>
  );
};

export default Navbar;
