import React, { forwardRef } from "react";
import classes from "./Banner.module.css";

const Banner = forwardRef(
  ({ section, id, topSpacing, parentIsVisible }, ref) => {
    return (
      <div
        ref={ref}
        id={id}
        className={`${topSpacing && classes["top-spacing"]} `}
      >
        <div
          className={`${classes["banner-container"]} ${
            parentIsVisible && classes["banner-visible"]
          }`}
        >
          <hr className={classes.line} />
          <div className={classes.title}>{section}</div>
          <hr className={classes.line} />
        </div>
      </div>
    );
  }
);

export default Banner;
