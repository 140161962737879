import React from "react";
import { sections } from "../../object-data/contactData";
import classes from "../../views/Footer.module.css";

function TopRow({ language }) {
  let topRowContainer = [];
  const { topRow } = sections;

  for (let column in topRow) {
    const { title, content } = topRow[column][language ? "spanish" : "english"];

    topRowContainer.push(
      <div className={classes.column} key={column}>
        <div className={classes.title}>{title}</div>

        {content.map((item) => (
          <div
            onClick={(e) => {
              e.preventDefault();

              if (item.email) {
                window.location.href = `mailto:${item.email}`;
                return;
              }

              if (item.link) {
                window.open(item.link, "_blank");
                return;
              }
            }}
            className={`${classes["text-container"]} ${
              item.hasLink && classes.pointer
            }`}
            key={item.text}
          >
            {item.icon ?? ""}
            {item.text}
          </div>
        ))}
      </div>
    );
  }

  return <>{topRowContainer}</>;
}

export default TopRow;
