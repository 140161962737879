import React from "react";
import classes from "./FooterIcons.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  faXTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

function FooterIcons() {
  const handleOpen = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className={classes["icons-container"]}>
      {/* <FontAwesomeIcon
          icon={faEnvelope}
          className={classes.icon}
          onClick={handleOpen}
        /> */}
      <FontAwesomeIcon
        onClick={() => {
          handleOpen("https://twitter.com/Bayer4CropsAR");
        }}
        icon={faXTwitter}
        className={classes.icon}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleOpen("https://www.instagram.com/bayeragro/");
        }}
        icon={faInstagram}
        className={classes.icon}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleOpen(
            "https://www.youtube.com/channel/UCpMnEw0NTg4IWqsgSKaqmHg"
          );
        }}
        icon={faYoutube}
        className={classes.icon}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleOpen("https://www.facebook.com/Bayer4CropsAR/");
        }}
        icon={faFacebook}
        className={classes.icon}
      />
      <FontAwesomeIcon
        onClick={() => {
          handleOpen("https://www.linkedin.com/company/1895/");
        }}
        icon={faLinkedinIn}
        className={classes.icon}
      />
    </div>
  );
}

export default FooterIcons;
