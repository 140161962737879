import { Outlet } from "react-router-dom";
import Header from "./Header";
import { withAuthenticator } from "@aws-amplify/ui-react";

const Layout = ({ handleLogout }) => {
  return (
    <div className="main-container">
      <Header handleLogout={handleLogout} />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default withAuthenticator(Layout);
