import React, { useRef } from "react";
import classes from "./Herramientas.module.css";
import AlternativeCardsContainer from "../components/AlternativeCardsContainer";
import Banner from "../components/Banner";
import useComponentVisibility from "../hooks/useComponentVisibility";
import ToolCarousel from "../components/ToolCarousel";

function Herramientas({ data, language }) {
  const ref = useRef(null);
  const [parentIsVisible] = useComponentVisibility({
    ref: ref,
    threshold: 0.5,
  });
  return (
    <>
      <Banner
        ref={ref}
        section={language ? "Herramientas" : "Tools"}
        id="herramientas"
        topSpacing={true}
        parentIsVisible={parentIsVisible}
      />
      <div className={classes["outer-herramientas-container"]}>
        <div className={classes["herramientas-container"]}>
          {data.map((category) => (
            <div
              className={classes["herramientas-category"]}
              id={category.id}
              key={category.id}
            >
              {!category.banner ? (
                <ToolCarousel
                  sectionId={category.id}
                  links={category.links}
                  language={language}
                  title={category[language ? "title-ES" : "title-EN"]}
                />
              ) : (
                <AlternativeCardsContainer
                  links={category.links}
                  language={language}
                  title={category[language ? "title-ES" : "title-EN"]}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Herramientas;
