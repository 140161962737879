import React, { useState } from "react";
import Category from "./Category";
import Banner from "../components/Banner";

function Regional({ language, categories }) {
  const [show, setShow] = useState(false);

  const handleShow = (value) => {
    setShow(value);
  };

  const animations = {
    even: [
      { direction: "top", delay: 0.7 },
      { direction: "right", delay: 0.9 },
      { direction: "left", delay: 1.1 },
      { direction: "bottom", delay: 1.8 },
    ],
    odd: [
      { direction: "top", delay: 0.7 },
      { direction: "right", delay: 0.9 },
      { direction: "bottom", delay: 1.1 },
      { direction: "right", delay: 1.8 },
    ],
  };

  return (
    <>
      <Banner
        section={language ? "Reportes Regionales" : "Regional Reports"}
        id="regional"
        parentIsVisible={show}
      />
      {categories.map((category, index) => {
        return (
          <Category
            key={index}
            category={category}
            language={language}
            animation={index % 2 ? animations.even : animations.odd}
            handleShow={handleShow}
          />
        );
      })}
    </>
  );
}

export default Regional;
