import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import Layout from "../components/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import DataContext from "../store/data-context";
import checkUser from "./checkUser";
import { fetchAllCards } from "../services/fetchAllCards";

const MainContainer = () => {
  const dataContext = useContext(DataContext);
  const { fullCategoriesData, saveCategories, handleAdmin } = dataContext;
  const [categoriesArr, setCategoriesArr] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    // Check credentials.
    checkUser()
      .then(async (res) => {
        if (!res) return;
        const { isAdmin } = res;

        res
          ?.credentials()
          .then(async (resp) => {
            const awsConfig = { region: res.region, credentials: resp };

            fetchAllCards({ awsConfig })
              .then((res) => setCategoriesArr(res))
              .catch((err) => console.log("err", err));

            if (!isAdmin && location.pathname === "/dashboard") {
              navigate("/unauthorized");
            }
            if (isAdmin) {
              handleAdmin(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });

        if (!res) {
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((e) => {
        console.log("oops", e);
        localStorage.clear();
        navigate("/");
      });
    // eslint-disable-next-line
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (categoriesArr) {
      saveCategories(categoriesArr);
    }
    // eslint-disable-next-line
  }, [categoriesArr]);

  // MANAGE SIGN OUT LOGIC BELOW
  const handleLogout = async () => {
    // MUST BE ASYNC

    try {
      localStorage.removeItem("didSignIn");
      localStorage.removeItem("didAuth");
      localStorage.setItem("didRemove", true);
      await Auth.signOut();
      window.location.reload(); // Refresh the page to go back to the login component
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const divStyle = {
    backgroundColor: "#060606",
    border: "solid rgba(0, 0, 255, 0)",
    borderBottom: "#060606",
    height: "100vh",
    display: "flex",
  };

  return (
    <>
      {fullCategoriesData ? (
        <Layout handleLogout={handleLogout} />
      ) : (
        <div className="login-container">
          <div style={divStyle}>
            <div className="loadingio-spinner-rolling-2zou7lmg8qf">
              <div className="ldio-uvxcd6wzxfj">
                <div></div>
              </div>
            </div>
            <div className="restricted">RESTRICTED</div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainContainer;
