import React from "react";
import classes from "./Footer.module.css";
import TopRow from "../components/contact/TopRow";
import MiddleRow from "../components/contact/MiddleRow";
import BottomRow from "../components/contact/BottomRow";

function AlternativeFooter({ language }) {
  return (
    <footer id="Contacto">
      <hr className={classes["thinner-line"]} />
      <div className={classes["top-row"]}>{<TopRow language={language} />}</div>
      <hr className={classes["thinner-line"]} />
      <div className={classes["middle-row"]}>
        <MiddleRow language={language} />
      </div>
      <hr className={classes["thinner-line"]} />
      <div className={classes["bottom-row"]}>
        <BottomRow language={language} />
      </div>
    </footer>
  );
}

export default AlternativeFooter;
