import DataContext from "./data-context";
import { useReducer } from "react";
import categoriesTemplate from "../object-data/categories-template";

const defaultState = {
  fullCategoriesData:
    localStorage.getItem("fullCategoriesData") !== "undefined"
      ? JSON.parse(localStorage.getItem("fullCategoriesData"))
      : null,
  sideScroll: { sectionId: "", index: 0 },
  language: JSON.parse(localStorage.getItem("language")) || true,
  isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SAVE_CATEGORIES": {
      const fullCategoriesData = JSON.parse(JSON.stringify(categoriesTemplate));

      action.categoriesArr.forEach((card) => {
        fullCategoriesData[card.parent.S]
          .find((section) => section["title-ES"] === card.section.S)
          .links.push({
            "title-ES": card.titleEs.S,
            "title-EN": card.titleEn.S,
            "subtitle-ES": card.descriptionEs.S,
            "subtitle-EN": card.descriptionEn.S,
            link: card.link.S,
            icon: card.icon.S,
            imageUrl: card.imageUrl,
            index: card.index.N,
          });
      });

      localStorage.setItem(
        "fullCategoriesData",
        JSON.stringify(fullCategoriesData)
      );

      return { ...state, fullCategoriesData: fullCategoriesData };
    }

    case "SET_LANGUAGE": {
      localStorage.setItem("language", action.language);

      return { ...state, language: action.language };
    }
    case "SCROLL_TO_INDEX": {
      const { sectionId, index } = action.payload;

      const set = sectionId.includes("herramientas") ? 3 : 4;

      return {
        ...state,
        sideScroll: { sectionId, index: Math.ceil(index / set) - 1 },
      };
    }

    case "CLEAR_SCROLL_INDEX": {
      return { ...state, sideScroll: { sectionId: "", index: 0 } };
    }

    case "HANDLE_ADMIN": {
      return { ...state, isAdmin: action.isAdmin };
    }

    default:
      return state;
  }
};

const DataProvider = (props) => {
  const [state, dispatchAction] = useReducer(reducer, defaultState);

  const saveCategories = (categoriesArr) => {
    dispatchAction({ type: "SAVE_CATEGORIES", categoriesArr });
  };

  const setLanguage = (language) => {
    dispatchAction({ type: "SET_LANGUAGE", language });
  };

  const scrollToIndex = (payload) => {
    dispatchAction({ type: "SCROLL_TO_INDEX", payload });
  };

  const clearScrollIndex = () => {
    dispatchAction({ type: "CLEAR_SCROLL_INDEX" });
  };

  const handleAdmin = (isAdmin) => {
    dispatchAction({ type: "HANDLE_ADMIN", isAdmin });
  };

  const dataContext = {
    ...state, // some values (counting criteria) are set dynamically.
    saveCategories,
    setLanguage,
    scrollToIndex,
    clearScrollIndex,
    handleAdmin,
  };

  return (
    <DataContext.Provider value={dataContext}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
