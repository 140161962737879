import { useContext } from "react";
import { Container } from "react-bootstrap";
import classes from "./Results.module.css";
import DataContext from "../../store/data-context";

const Results = ({ didInput, filteredResults, language, handleClick }) => {
  const dataContext = useContext(DataContext);
  const { scrollToIndex } = dataContext;
  const handleFind = (url, index) => {
    // url is defined per section. index hints at the index for each chunk of cards
    const targetElement = document.getElementById(url);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    scrollToIndex({ sectionId: url, index: parseInt(index) });
    handleClick();
  };

  return (
    <>
      {didInput && (
        <Container
          style={{ overflowY: "scroll" }}
          className={classes.results__container}
        >
          {filteredResults.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.result__container}
                onClick={() => {
                  handleFind(item.url, item.index);
                }}
              >
                <div className={classes.result__title}>
                  {language ? item["title-ES"] : item["title-EN"]}
                </div>
                <div className={classes.result__subtext}>
                  {language ? item["section-ES"] : item["section-EN"]}
                </div>
              </div>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default Results;
