import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(null);

  const handleLogin = async () => {
    localStorage.setItem("didSignIn", true);

    try {
      await Auth.federatedSignIn(); // Perform federated sign-in
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  useEffect(() => {
    setShowLoading(true);
  }, []);

  useEffect(() => {
    let intervalId;

    if (!JSON.parse(localStorage.getItem("didSignIn"))) {
      handleLogin();
    }

    if (JSON.parse(localStorage.getItem("didAuth"))) {
      navigate("/home");
    }

    if (!JSON.parse(localStorage.getItem("didAuth"))) {
      intervalId = setInterval(() => {
        handleLogin();
      }, 4000);
    }

    return () => {
      // Clear the interval when the component unmounts
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [navigate]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn": {
          return;
        }
        case "cognitoHostedUI": {
          localStorage.setItem("didAuth", true);
          navigate("/home");
          return;
        }

        case "signIn_failure": {
          console.log("signinfailure", data);
          return;
        }
        case "cognitoHostedUI_failure": {
          console.log("Error", data);
          return;
        }

        default: {
          return;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-container">
      {showLoading && (
        <div className={classes["alt-bg"]}>
          <div className="loadingio-spinner-rolling-2zou7lmg8qf">
            <div className="ldio-uvxcd6wzxfj">
              <div></div>
            </div>
          </div>
          <div className="restricted">RESTRICTED</div>
        </div>
      )}
    </div>
  );
};

export default Login;
