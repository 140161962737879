import React, { useRef, useEffect, useState } from "react";
import CardsContainer from "../components/CardsContainer";
import DescriptionContainer from "../components/DescriptionContainer";
import classes from "./Category.module.css";
import useComponentVisibility from "../hooks/useComponentVisibility";

const Category = ({ category, language, animation, handleShow }) => {
  const cardRef = useRef();
  const [cardIsVisible, setCardIsVisible] = useState(false);

  const [categoryIsVisible, categoryDidShow] = useComponentVisibility({
    ref: cardRef,
    loop: false,
    threshold: 0.5,
  });

  useEffect(() => {
    setCardIsVisible(categoryIsVisible);
    handleShow(categoryIsVisible);
    // eslint-disable-next-line
  }, [categoryIsVisible]);

  return (
    <div
      id={
        category.id
          ? category.id.replace(" ", "_")
          : category["title-ES"].replace(" ", "_")
      }
      ref={cardRef}
      className={`${classes["outer-category-container"]} ${
        category.links.length < 4 && classes["shorter-margin"]
      }`}
    >
      <div className={classes["category-container"]}>
        <DescriptionContainer
          title={category[language ? "title-ES" : "title-EN"]}
          message={category[language ? "message-ES" : "message-EN"]}
          description={category[language ? "description-ES" : "description-EN"]}
          show={cardIsVisible}
          image={category.image}
        />
        <CardsContainer
          sectionId={category.id}
          links={category.links}
          show={cardIsVisible}
          language={language}
          animation={animation}
          didShow={categoryDidShow}
        />
      </div>
    </div>
  );
};

export default Category;
