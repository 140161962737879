import React from "react";
import classes from "../../views/Footer.module.css";
import { sections } from "../../object-data/contactData";

function MiddleRow({ language }) {
  const { middleRow } = sections;
  return (
    <>
      {middleRow.map((entry, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <div key={"line" + index} className={classes.division}></div>
          )}
          <a key={index} href={entry.link} className={classes.link}>
            {entry[language ? "spanish" : "english"]}
          </a>
        </React.Fragment>
      ))}
    </>
  );
}

export default MiddleRow;
