import React, { useRef } from "react";
import classes from "./MissionAlt.module.css";
import useComponentVisibility from "../../hooks/useComponentVisibility";

function MissionAlt() {
  const componentRef = useRef(null);
  const [isComponentVisible, didShow] = useComponentVisibility({
    ref: componentRef,
    threshold: 0.8,
  });

  return (
    <div ref={componentRef} id="mission" style={{ paddingTop: "14vh" }}>
      <div
        className={`${!didShow && classes.intro} ${
          classes["mission-container"]
        } ${classes[isComponentVisible ? "mission-visible" : ""]}`}
      >
        <div className={classes["mission-header"]}>
          <div
            className={`${classes["header-arrow-one"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/three-arrows.png"
              alt="community banner"
            />
          </div>
          <div
            className={`${classes["header-text"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/mission-header.png"
              alt="community banner"
            />
          </div>
          <div
            className={`${classes["header-arrow-two"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/three-arrows.png"
              alt="community banner"
            />
          </div>
        </div>
        <div className={classes["mission-main"]}>
          <div
            className={`${classes["main-line-1"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/1-line.png"
              alt="community banner"
            />
          </div>
          <div
            className={`${classes["main-line-2"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/2-line.png"
              alt="community banner"
            />
          </div>
          <div
            className={`${classes["main-line-3"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/3-line.png"
              alt="community banner"
            />
          </div>
          <div
            className={`${classes["main-line-4"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/4-line.png"
              alt="community banner"
            />
          </div>
        </div>
        <div className={classes["side-arrows-container"]}>
          <div className={classes["left-arrows"]}>
            <div
              className={`${classes["bigger-arrow"]}  ${
                classes[isComponentVisible ? "mission-visible" : ""]
              }`}
            >
              <img
                src="images/banners/mission/single-arrow.png"
                alt="community banner"
              />
            </div>
            <div
              className={`${classes["smaller-arrow"]}  ${
                classes[isComponentVisible ? "mission-visible" : ""]
              }`}
            >
              <img
                src="images/banners/mission/single-arrow.png"
                alt="community banner"
              />
            </div>
          </div>
          <div
            className={`${classes["big-arrow-right"]}  ${
              classes[isComponentVisible ? "mission-visible" : ""]
            }`}
          >
            <img
              src="images/banners/mission/single-arrow.png"
              alt="community banner"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionAlt;
