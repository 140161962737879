import React, { useRef, useState, useEffect, useContext } from "react";
import DataContext from "../store/data-context";
import Carousel from "react-bootstrap/Carousel";
import AlternativeCard from "./AlternativeCard";
import classes from "./ToolCarousel.module.css";
import useComponentVisibility from "../hooks/useComponentVisibility";
import "./ToolCarousel.css";

function ToolCarousel({ links, language, title, sectionId }) {
  links.sort((a, b) => {
    if (a.index === b.index) {
      return a["title-ES"].localeCompare(b["title-ES"]);
    }
    return a.index - b.index;
  });

  const dataContext = useContext(DataContext);
  const { sideScroll, clearScrollIndex } = dataContext;

  const containerRef = useRef(null);
  const [containerIsVisible] = useComponentVisibility({
    ref: containerRef,
    loop: false,
    threshold: 0.1,
  });
  const [activeChunk, setActiveChunk] = useState(0); // State to track active chunk index

  useEffect(() => {
    if (sideScroll.sectionId === sectionId && containerIsVisible) {
      goToChunk(sideScroll.index);
      clearScrollIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideScroll, sectionId, containerIsVisible]);

  let chunks = groupObjectsIntoArrays(links);

  // Function to navigate to a specific chunk
  const goToChunk = (index) => {
    setActiveChunk(index);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`${classes["section-container"]} ${
          containerIsVisible && classes.visible
        }`}
      >
        {title}
      </div>
      <Carousel
        pause="hover"
        wrap="true"
        touch="true"
        activeIndex={activeChunk} // Set active index of carousel
        onSelect={(selectedIndex) => setActiveChunk(selectedIndex)} // Update activeChunk on select
        prevIcon={
          <span className={`${classes["custom-carousel-icon-left"]}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
              <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
            </svg>
          </span>
        }
        nextIcon={
          <span className={`${classes["custom-carousel-icon-right"]}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
          <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
        </svg>`,
              }}
            />
          </span>
        }
      >
        {chunks.map((chunk, chunkIndex) => (
          <Carousel.Item key={chunkIndex}>
            <div className={`${classes["cards-container"]} `}>
              {chunk.map((entry, entryIndex) => (
                <AlternativeCard
                  key={entryIndex} // Use the entryIndex as the key
                  id={entry["title-ES"]}
                  data={entry}
                  language={language}
                  delay={entryIndex < 3 ? 200 * entryIndex : 200}
                />
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div></div>
    </>
  );
}

export default ToolCarousel;

function groupObjectsIntoArrays(objects) {
  // Initialize an empty array to store arrays
  const arrays = [];

  // Iterate over the objects and group them into sets of three
  for (let i = 0; i < objects.length; i += 3) {
    const objectSet = objects.slice(i, i + 3);
    arrays.push(objectSet);
  }

  return arrays;
}
