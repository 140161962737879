import React, { useState, useContext } from "react";
import MissionAlt from "../components/mission/MissionAlt";
// import categories from "../object-data/categories";
import Herramientas from "../views/Herramientas";
import DataContext from "../store/data-context";
import Contact from "../views/Contact";
import Hero from "../views/Hero";
import Regional from "../views/Regional";
import Global from "../views/Global";
import Footer from "../views/Footer";

const Home = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const dataContext = useContext(DataContext);
  const { language, fullCategoriesData } = dataContext;

  return (
    <div className="home-container">
      <Hero language={language} />

      <div className="category-scroll">
        <MissionAlt />
        <Regional
          language={language}
          categories={fullCategoriesData["Regional"]}
        />
        <Global language={language} categories={fullCategoriesData["Global"]} />
        <Herramientas
          data={fullCategoriesData["Herramientas"]}
          language={language}
        />

        <Footer language={language} />
        <Contact
          show={showContactModal}
          handleClose={() => {
            setShowContactModal(false);
          }}
          language={language}
        />
      </div>

      <div className="restricted">RESTRICTED</div>
    </div>
  );
};

export default Home;
