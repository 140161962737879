import { useState, useRef } from "react";
import Dropdown from "./Dropdown";
import DataContext from "../store/data-context";
import { useContext } from "react";

const MenuItems = ({
  items,
  depthLevel,

  isDropdownItem,
  isFirst,
}) => {
  const dataContext = useContext(DataContext);
  const { language } = dataContext;

  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  const onMouseEnter = (event) => {
    if (event.type === "mouseenter") {
      // Handle mouse event
      window.innerWidth > 960 && setDropdown(true);
    } else if (event.type === "touchstart") {
      // Handle touch event
    }
  };

  const onMouseLeave = (event) => {
    if (event.type === "mouseleave") {
      // Handle mouse event
      window.innerWidth > 960 && setDropdown(false);
    }
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <div className={isDropdownItem ? "vertical-item-container" : ""}>
      <li
        className={`${
          isDropdownItem ? `menu-items vertical-menu-items` : `menu-items`
        }  `}
        ref={ref}
        onTouchStart={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onClick={closeDropdown}
      >
        {items.url && items.submenu ? (
          <div className={isDropdownItem ? "" : "menu-item-container"}>
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={!!dropdown}
              onClick={(e) => setDropdown((prev) => !prev)}
            >
              {window.innerWidth < 960 && depthLevel === 0 ? (
                items[language ? "title-ES" : "title-EN"]
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    // Scroll to the element with the specified ID

                    const targetElement = document.getElementById(
                      items.url.replace(" ", "_")
                    );
                    if (targetElement) {
                      targetElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  onTouchStart={() => {
                    return;
                  }}
                >
                  {items[language ? "title-ES" : "title-EN"]}
                </div> // The ones with an arrow
              )}

              {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel >
                  0 && window.innerWidth > 960 ? (
                <div className="down-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height=".8em"
                    viewBox="0 0 256 512"
                  >
                    <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
                  </svg>
                </div>
              ) : (
                <div className="down-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
                  </svg>
                </div>
              )}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
              isFirst={isFirst}
            />
          </div>
        ) : !items.url && items.submenu ? (
          <div>
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
            >
              {items[language ? "title-ES" : "title-EN"]}
              {depthLevel > 0 ? (
                <span>&raquo;</span>
              ) : (
                <span className="arrow" />
              )}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </div>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();

              // Meant for Experiencia Maria Eugenia. Will redirect.
              if (items.link) {
                window.open(items.link, "_blank");
                return;
              }
              // Scroll to the element with the specified ID
              const targetElement = document.getElementById(
                items.id
                  ? items.id.replace(" ", "_")
                  : items["title-ES"].replace(" ", "_")
              );
              if (targetElement) {
                targetElement.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            {items[language ? "title-ES" : "title-EN"]}
          </button>
        )}
      </li>
    </div>
  );
};

export default MenuItems;
