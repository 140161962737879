import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./contactData.module.css";

export const sections = {
  topRow: {
    nosotros: {
      spanish: {
        title: "Nosotros",
        content: [
          {
            text: "Planta María Eugenia",
          },
          {
            text: "+54 2475 469500",
            icon: <FontAwesomeIcon icon={faPhone} className={classes.icon} />,
          },
          {
            text: "¡Escribinos vía email!",
            email: "comunicacionesrowcrops@bayer.com",
            icon: (
              <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
            ),
            hasLink: true,
          },
          {
            text: "Ruta 31 Km 170, Rojas",
            icon: (
              <FontAwesomeIcon icon={faLocationDot} className={classes.icon} />
            ),
          },
        ],
      },
      english: {
        title: "About Us",
        content: [
          {
            text: "Maria Eugenia Plant",
          },
          {
            text: "+54 2475 469513",
            icon: <FontAwesomeIcon icon={faPhone} className={classes.icon} />,
          },
          {
            text: "Contact us via email!",
            email: "comunicacionesrowcrops@bayer.com",
            hasLink: true,

            icon: (
              <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
            ),
          },
          {
            text: "Route 31 Km 170, Rojas",
            icon: <FontAwesomeIcon icon={faLocationDot} />,
          },
        ],
      },
    },
    visitantes: {
      spanish: {
        title: "Visitantes",
        content: [
          {
            text: "Comunidad",
            link: "https://www.plantamariaeugenia.com/public/#/comienzo",
            hasLink: true,
          },
          {
            text: "Negocios",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
          {
            text: "Bayer",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
        ],
      },
      english: {
        title: "Visitors",
        content: [
          {
            text: "Community",
            link: "https://www.plantamariaeugenia.com/public/#/comienzo",
            hasLink: true,
          },
          {
            text: "Businesses",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
          {
            text: "Bayer",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
        ],
      },
    },
    servicios: {
      spanish: {
        title: "Servicios",
        content: [
          {
            text: "Cómo llegar",
            link: "https://www.google.com/maps/place/Planta+Maria+Eugenia+-+Bayer+Crop+Science/@-34.1962493,-60.7297474,13.75z/data=!4m8!1m2!2m1!1s82+5+de+la+ciudad+de+Rojas+Provincia+de+Buenos+Aires!3m4!1s0x95b85689678ca6bb:0xd9f5e94dbb64d856!8m2!3d-34.2007376!4d-60.6917596",
            hasLink: true,
          },
          {
            text: "Editar Visita Presencial",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
          {
            text: "Validar inducción SSHE",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
        ],
      },
      english: {
        title: "Services",
        content: [
          {
            text: "How to get there",
            link: "https://www.google.com/maps/place/Planta+Maria+Eugenia+-+Bayer+Crop+Science/@-34.1962493,-60.7297474,13.75z/data=!4m8!1m2!2m1!1s82+5+de+la+ciudad+de+Rojas+Provincia+de+Buenos+Aires!3m4!1s0x95b85689678ca6bb:0xd9f5e94dbb64d856!8m2!3d-34.2007376!4d-60.6917596",
            hasLink: true,
          },
          {
            text: "Edit On-Site Visit",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
          {
            text: "Validate SSHE induction",
            link: "https://www.plantamariaeugenia.com/",
            hasLink: true,
          },
        ],
      },
    },
    contactanos: {
      spanish: {
        title: "Contactanos",
        content: [
          {
            text: "Sitio Web Corporativo",
            link: "https://www.conosur.bayer.com/es/",
            hasLink: true,
          },
          {
            text: "Dekalb Argentina",
            link: "https://www.dekalb.com.ar/es-ar.html",
            hasLink: true,
          },
          {
            text: "La Tijereta",
            link: "https://www.latijereta.com.ar/es-ar.html",
            hasLink: true,
          },
        ],
      },
      english: {
        title: "Contact Us",
        content: [
          {
            text: "Corporate Website",
            link: "https://www.conosur.bayer.com/es/",
            hasLink: true,
          },
          {
            text: "Dekalb Argentina",
            link: "https://www.dekalb.com.ar/es-ar.html",
            hasLink: true,
          },
          {
            text: "La Tijereta",
            link: "https://www.latijereta.com.ar/es-ar.html",
            hasLink: true,
          },
        ],
      },
    },
  },
  middleRow: [
    {
      spanish: "Condiciones de Uso",
      english: "Terms of Use",
      link: "https://www.conosur.bayer.com/es/condiciones-de-uso",
    },
    {
      spanish: "Políticas de Privacidad",
      english: "Privacy Policy",
      link: "https://www.conosur.bayer.com/es/politica-de-privacidad",
    },
    {
      spanish: "Pie de Imprenta",
      english: "Publisher's imprint",
      link: "https://www.conosur.bayer.com/es/pie-de-imprenta",
    },
    {
      spanish: "Detalles Técnicos",
      english: "Technical Details",
      link: "https://www.conosur.bayer.com/es/detalles-tecnicos",
    },
    {
      spanish: "Contacto",
      english: "Contact",
      email: "comunicacionesrowcrops@bayer.com",
    },
    {
      spanish: "Términos y Condiciones",
      english: "Terms and Conditions",
      link: "https://www.conosur.bayer.com/es/condiciones-de-uso",
    },
  ],
};
