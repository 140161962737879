import React, { useRef, useEffect, useState, useContext } from "react";
import styles from "./SearchBox.module.css"; // Importing CSS module
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import Results from "./Results";
import DataContext from "../../store/data-context";
import { categoriesToSearchResults } from "../../data-handling/dataHandling";

const SearchBox = ({ handleClick }) => {
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const dataContext = useContext(DataContext);
  const { language, fullCategoriesData } = dataContext;
  const availableResults = categoriesToSearchResults(fullCategoriesData);

  const handleQuery = (e) => {
    setQuery(e.target.value);
    const currentMatches = [];

    availableResults.forEach((entry) => {
      if (
        entry["title-EN"]
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase()) ||
        entry["title-ES"]
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      ) {
        currentMatches.push(entry);
      }
    });
    setFilteredResults(currentMatches);
  };

  const handleFocus = () => {
    // Trigger focus on the input element when clicked
    inputRef.current?.focus();
  };

  useEffect(() => {
    handleFocus();
  }, []);

  return (
    <Modal open={true} hideButtons={true} onClose={handleClick}>
      <div className={styles.main__container}>
        <div className={styles.search__box__container}>
          <form className={styles.search}>
            <div className={styles.search__wrapper}>
              <input
                type="text"
                name=""
                placeholder={language ? "Buscar..." : "Search for..."}
                className={styles.search__field}
                ref={inputRef}
                onChange={handleQuery}
                value={query}
              />
              <button type="submit" className={styles.search__icon}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </form>
        </div>
        <Results
          handleClick={handleClick}
          language={language}
          filteredResults={filteredResults}
          didInput={!!query.length}
        />
      </div>
    </Modal>
  );
};

export default SearchBox;
