import React, { useState, useEffect, useContext } from "react";
import classes from "./Dashboard.module.css";
import NewCardForm from "./NewCardForm";
import checkUser from "../../auth/checkUser.js";
import { fetchAllCards } from "../../services/fetchAllCards.js";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import DataContext from "../../store/data-context.js";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState(null);
  const dataContext = useContext(DataContext);
  const { saveCategories } = dataContext;
  const [cardsData, setCardsData] = useState([]);
  const [selections, setSelections] = useState({
    action: "",
    parent: "",
    section: "",
  });
  const [selectedCard, setSelectedCard] = useState("");
  const [selectedCardData, setSelectedCardData] = useState({ titleEs: "" });
  const [deletionResponse, setDeletionResponse] = useState({
    error: "",
    message: "",
  });
  const [didUpdateCards, setDidUpdateCards] = useState(null);

  const isNewCardFormVisible =
    (selections.action === "add" && selections.parent && selections.section) ||
    (selections.action === "put" && selectedCard && selectedCardData.titleEs);

  const options = {
    actions: {
      add: "Crear una tarjeta nueva",
      put: "Editar una tarjeta",
      delete: "Eliminar una tarjeta",
    },
    parent: ["Regional", "Global", "Herramientas"],
    section: {
      Regional: ["Campo", "Sitio", "Calidad", "Modelos"],
      Global: ["Modelos"],
      Herramientas: ["Campo", "Sitio"],
    },
  };

  useEffect(() => {
    checkUser().then(async (res) => {
      res
        ?.credentials()
        .then(async (resp) => {
          const awsConfig = { region: res.region, credentials: resp };
          setCredentials(awsConfig);
        })
        .catch((err) => {
          setCredentials(null);
          console.log("Oops", err);
          localStorage.clear();
          navigate("/");
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    credentials &&
      didUpdateCards !== false &&
      fetchAllCards({ awsConfig: credentials })
        .then((res) => {
          if (!res) throw new Error("No response");
          setCardsData(res);
          saveCategories(res);
          setDidUpdateCards(false);
        })
        .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [credentials, didUpdateCards]);

  useEffect(() => {
    if (selectedCard) {
      const currentCardData = cardsData.find(
        (card) =>
          card.titleEs.S === selectedCard &&
          card.parent.S === selections.parent &&
          card.section.S === selections.section
      );
      setSelectedCardData(currentCardData);
    }
  }, [selectedCard, cardsData, selections.parent, selections.section]);

  const handleSelectionChange = (field) => (e) => {
    const value = e.target.value;
    setSelections((prevSelections) => {
      const newSelections = { ...prevSelections, [field]: value };

      setSelectedCard("");
      setSelectedCardData({ titleEs: "" });

      // Reset dependent fields
      if (field === "action") {
        newSelections.parent = "";
        newSelections.section = "";
      } else if (field === "parent") {
        newSelections.section = "";
      }

      return newSelections;
    });
  };

  const handleReset = () => {
    setSelections((prevState) => ({
      ...prevState,
      section: "",
    }));
    setSelectedCard("");
    setSelectedCardData({ titleEs: "" });
    setDeletionResponse({
      error: "",
      message: "",
    });
    setDidUpdateCards(true);
  };

  const handleDelete = async () => {
    try {
      const lambda = new LambdaClient(credentials);

      const params = {
        FunctionName: "portalDeleteCardHandler", // hide lambda name
        Payload: JSON.stringify({
          id: selectedCard,
          parent: selections.parent,
        }),
      };

      const response = await lambda.send(new InvokeCommand(params));
      const data = JSON.parse(new TextDecoder().decode(response.Payload));
      setDeletionResponse((prevState) => ({
        ...prevState,
        message: data.body,
      }));

      setDidUpdateCards(true);
    } catch (error) {
      setDeletionResponse((prevState) => ({
        ...prevState,
        error,
      }));
    }
  };

  useEffect(() => {
    if (deletionResponse.message) {
      setTimeout(() => {
        handleReset();
      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletionResponse.message]);

  return (
    <div className={classes.dashboard__container}>
      <div className={classes.main__container}>
        <div className={classes.title}>Editor de Tarjetas</div>
        {!isNewCardFormVisible && (
          <>
            <select
              className={`form-select ${classes.form__select}`}
              value={selections.action}
              onChange={handleSelectionChange("action")}
              aria-label="Select action"
            >
              <option value="" disabled>
                Seleccione una acción
              </option>
              {Object.entries(options.actions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
            {selections.action && (
              <select
                className={`form-select ${classes.form__select}`}
                value={selections.parent}
                onChange={handleSelectionChange("parent")}
                aria-label="Select parent"
              >
                <option value="" disabled>
                  Seleccione una sección
                </option>
                {options.parent.map((parent) => (
                  <option key={parent} value={parent}>
                    {parent}
                  </option>
                ))}
              </select>
            )}
            {selections.parent && (
              <select
                className={`form-select ${classes.form__select}`}
                value={selections.section}
                onChange={handleSelectionChange("section")}
                aria-label="Select section"
              >
                <option value="" disabled>
                  Seleccione una subsección
                </option>
                {options.section[selections.parent].map((section) => (
                  <option key={section} value={section}>
                    {section}
                  </option>
                ))}
              </select>
            )}
            {selections.section &&
            cardsData.length &&
            selections.action !== "add" ? (
              <select
                className={`form-select ${classes.form__select}`}
                value={selectedCard}
                onChange={(e) => {
                  setSelectedCard(e.target.value);
                }}
                aria-label="Select section"
              >
                <option value="" disabled>
                  {cardsData.filter(
                    (card) =>
                      card.parent["S"] === selections.parent &&
                      card.section["S"] === selections.section
                  ).length
                    ? "Seleccione una tarjeta"
                    : "No existen tarjetas para esta categoria"}
                </option>
                {cardsData
                  .filter(
                    (card) =>
                      card.parent["S"] === selections.parent &&
                      card.section["S"] === selections.section
                  )
                  .map((card) => (
                    <option key={card.titleEs["S"]} value={card.titleEs["S"]}>
                      {card.titleEs["S"]}
                    </option>
                  ))}
              </select>
            ) : null}
            <span className={classes.spacing} />
            {selections.action === "delete" && selectedCard && (
              <>
                <button
                  className={`btn btn-primary ${classes.button}`}
                  children="Eliminar"
                  onClick={handleDelete}
                />
                {deletionResponse.error && (
                  <p className="text-danger">{deletionResponse.error}</p>
                )}
                {deletionResponse.message && (
                  <p className="text-success">{deletionResponse.message}</p>
                )}
              </>
            )}
          </>
        )}
        {selections.action === "add" &&
          selections.parent &&
          selections.section &&
          cardsData.length && (
            <NewCardForm
              handleReset={handleReset}
              parent={selections.parent}
              section={selections.section}
              action="add"
              credentials={credentials}
              cardsLength={
                cardsData.filter(
                  (card) =>
                    card.parent["S"] === selections.parent &&
                    card.section["S"] === selections.section
                ).length
              }
            />
          )}
        {selections.action === "put" &&
          selectedCard &&
          selectedCardData.titleEs &&
          cardsData.length && (
            <NewCardForm
              existingCardData={selectedCardData}
              handleReset={handleReset}
              parent={selections.parent}
              section={selections.section}
              action="put"
              credentials={credentials}
              cardsLength={
                cardsData.filter(
                  (card) =>
                    card.parent["S"] === selections.parent &&
                    card.section["S"] === selections.section
                ).length
              }
            />
          )}
      </div>
    </div>
  );
};

export default Dashboard;
