import React from "react";
import classes from "./Card.module.css";
import { svgIcons } from "../icons/svg-icons";

function Card({
  title,
  subtitle,
  link,
  show,
  icon,
  language,
  direction,
  delay,
  didShow,
  unfinished,
}) {
  const handleVisitClick = (unfinished) => {
    if (unfinished) return;
    window.open(link, "_blank");
  };

  return (
    <div
      style={
        !didShow
          ? {
              transition: `opacity 1s ease-in-out, transform ${delay}s ease`,
            }
          : {}
      }
      className={`${classes.card} ${classes[`hidden-${direction}`]} ${
        show && classes.visible
      }`}
    >
      <div className={classes["text-area"]}>
        <div className={classes["icon-container"]}>
          <div
            style={{
              width: `${svgIcons[icon].width * 3}vw`,
              height: `${svgIcons[icon].height * 3}vw`,
            }}
            dangerouslySetInnerHTML={{ __html: svgIcons[icon].icon }}
          />
        </div>

        <div className={classes["card-title"]}>{title}</div>
        <div className={classes["card-subtitle"]}>{subtitle}</div>
      </div>
      <div
        className={`${classes["card-link"]} ${
          unfinished && classes.unfinished
        }`}
        onClick={() => handleVisitClick(unfinished)}
      >
        {language ? "Visitar sitio" : "Visit site"}
      </div>
    </div>
  );
}

export default Card;
