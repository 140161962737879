const categoriesTemplate = {
  Regional: [
    {
      "title-ES": "Campo",
      "title-EN": "Field",
      id: "regional-campo",
      "message-ES": "Explora los campos a fondo.",
      "message-EN": "Explore the fields in-depth.",
      image: "images/bayer-pictures/fields_air_view.png",
      "subtitle-ES":
        "Indicadores Producción Campos Comercial, Siembra a Cosecha.",
      "subtitle-EN": "KPIs Commercial Field Production, Planting to Harvest.",

      "description-ES":
        "Informes precisos y herramientas para la optimización de procesos y gestión de recursos.",
      "description-EN":
        "Accurate reports and tools for process optimization and resource management.",
      links: [],
    },
    {
      "title-ES": "Sitio",
      "title-EN": "Site",
      id: "regional-sitio",
      "message-ES": "Una visión integral de Row Crops",
      "message-EN": "A comprehensive view of Row Crops",
      image: "images/bayer-pictures/supply_chain.png",
      "description-ES":
        "Informes precisos y herramientas para la optimización de procesos y gestión de recursos.",
      "description-EN":
        "Accurate reports and tools for process optimization and resource management.",
      links: [],
    },
    {
      "title-ES": "Calidad",
      "title-EN": "Quality Assurance",

      id: "regional-calidad",
      "message-ES": "Conoce nuestros estándares.",
      "message-EN": "Know our standards.",
      image: "images/bayer-pictures/lab-quality.png",
      "description-ES":
        "Herramientas de seguimiento y análisis de calidad. Desde la etapa precomercial hasta el empaquetado.",
      "description-EN":
        "Tools for monitoring and analyzing quality. From pre-commercial stage to packaging.",
      links: [],
    },
    {
      "title-ES": "Modelos",
      "title-EN": "Models",
      id: "regional-modelos",
      "message-ES": "Explora modelos especializados para el análisis agrícola.",
      "message-EN": "Explore specialized models for agricultural analysis.",
      image: "images/bayer-pictures/reviewing_data.png",
      "description-ES": "",
      "description-EN": "",
      links: [],
    },
  ],
  Global: [
    {
      "title-ES": "Modelos",
      "title-EN": "Models",
      id: "global-modelos",
      "message-ES": "Explora modelos especializados para el análisis agrícola.",
      "message-EN": "Explore specialized models for agricultural analysis.",
      image: "images/bayer-pictures/modelos-global.png",
      "description-ES": "",
      "description-EN": "",
      links: [],
    },
  ],
  Herramientas: [
    {
      "title-ES": "Campo",
      "title-EN": "Field",

      id: "herramientas-campo",
      links: [],
    },
    {
      "title-ES": "Sitio",
      "title-EN": "Site",
      id: "herramientas-sitio",
      links: [],
    },

    {
      "title-ES": "Equipo",
      "title-EN": "Team",
      id: "herramientas-equipo",
      links: [],
      banner: true,
    },
  ],
};

export default categoriesTemplate;
