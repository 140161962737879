import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

export const fetchAllCards = async ({ awsConfig }) => {
  try {
    // Create an instance of the Lambda service with the specified awsConfig
    const lambdaClient = new LambdaClient(awsConfig);

    // Define the parameters for invoking the Lambda function
    const params = {
      FunctionName: "portalGetCardsHandler",
    };

    // Invoke the Lambda function
    const response = await lambdaClient.send(new InvokeCommand(params));

    // Extract the payload from the response
    // Convert the Uint8Array payload to a string
    const decoder = new TextDecoder("utf-8");
    const payloadString = decoder.decode(response.Payload);

    // Parse the payload string as JSON
    const parsedPayload = JSON.parse(payloadString);

    // Extract the body from the parsed payload
    const body = parsedPayload.body;

    // Parse the payload string as JSON

    // Resolve the promise with the parsed payload
    return body;
  } catch (error) {
    console.error("Error in fetchAllCards function:", error);
    throw error;
  }
};
