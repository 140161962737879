import React, { useRef } from "react";
import useComponentVisibility from "../hooks/useComponentVisibility";
// import { svgIcons } from "../icons/svg-icons";
function Hero({ language }) {
  const imageRef = useRef(null);
  const [isImageVisible, didImageShow] = useComponentVisibility({
    ref: imageRef,
    threshold: 0.5,
    loop: true,
  });

  // const svgStyle = {
  //   fill: "#ffffff",
  // };
  return (
    <>
      <div className="landing-container" id="Home">
        <div
          ref={imageRef}
          className={`image-container ${isImageVisible ? "loaded" : ""}`}
        >
          <img src="images/argentina-from-space.png" alt="world background" />
        </div>
        <div
          className={`${!didImageShow ? "intro" : ""} hero ${
            isImageVisible ? "loaded-hero" : ""
          }`}
        >
          <div className={`team-banner`}>Crop Science</div>
          <div className="title">Cono Sur Row Crops Manufacturing</div>
          <div className="subtitle">
            {language
              ? "Portal de gestión y operación de Producción de Semillas de Argentina"
              : "Management and Operation Portal for Seed Production in Argentina"}
          </div>
          <div className="logo-container">
            <div
              onClick={(e) => {
                e.preventDefault();
                // Scroll to the element with the specified ID
                const targetElement = document.getElementById("mission");
                if (targetElement) {
                  targetElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            >
              <img src="logo192.png" alt="logo" />
              <div className="logo-text">
                <div>Data</div>
                <div>Intelligence</div>
              </div>
            </div>
          </div>
          <div className="vertical-line"></div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            marginTop: "50vh",
            maxWidth: "1287px",
          }}
        ></div>
        {/* <div class="container-arrow-mission">
          <a href="#mission">
            <div
              style={svgStyle}
              className="downward-arrow"
              dangerouslySetInnerHTML={{ __html: svgIcons["downward-arrow"] }}
            />
          </a>
        </div> */}
        <div
          className={`${!didImageShow ? "intro" : ""} powered ${
            isImageVisible ? "loaded-hero" : ""
          }`}
        >
          Powered by<span> Production Strategy</span>
        </div>
      </div>
    </>
  );
}

export default Hero;
