import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import useComponentVisibility from "../hooks/useComponentVisibility";
import logo from "../images/Corp-Logo_BG_Bayer-Cross_Basic_on-screen_RGB.svg";
import AdminNavbar from "./AdminNavbar";

const Header = ({ handleLogout }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [headerBackgroundOpacity, setHeaderBackgroundOpacity] = useState(0); // Start with fully visible background
  const headerRef = useRef(null);
  const [headerIsVisible, headerDidShow] = useComponentVisibility({
    ref: headerRef,
    threshold: 0.5,
  });
  // Function to handle scroll events
  const handleScroll = () => {
    // Calculate the scroll position
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the opacity for the background color
    const backgroundOpacity = scrollY / 1000; // You can adjust the divisor for different sensitivity

    // Ensure the background opacity stays within the range [0, 1]
    const clampedBackgroundOpacity = Math.min(
      1,
      Math.max(0, backgroundOpacity)
    );

    // Ensure the border opacity stays within the range [0, 0.23]

    // Update the state with the new opacity values
    setHeaderBackgroundOpacity(clampedBackgroundOpacity);
  };

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      ref={headerRef}
      className={`${!headerDidShow && "intro"}  `}
      style={{
        backgroundColor: `rgba(16, 23, 33, ${
          currentPath === "/dashboard" ? 0 : headerBackgroundOpacity
        })`,
        opacity: headerIsVisible ? 1 : null,
      }}
    >
      <div className="nav-area">
        <Link to="https://bayernet.int.bayer.com/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        {currentPath !== "/dashboard" && <Navbar handleLogout={handleLogout} />}
        {currentPath === "/dashboard" && (
          <AdminNavbar handleLogout={handleLogout} />
        )}
      </div>
    </header>
  );
};

export default Header;
