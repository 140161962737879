import React, { useRef } from "react";
import classes from "./AlternativeCardsContainer.module.css";
import useComponentVisibility from "../hooks/useComponentVisibility";

function AlternativeCardsContainer({ links, title }) {
  const containerRef = useRef(null);
  const [containerIsVisible] = useComponentVisibility({
    ref: containerRef,
    loop: false,
  });

  const openLinkInNewWindow = () => {
    window.open(
      "https://bayergroup.sharepoint.com/sites/ComunicacinRowCrops/SitePages/Home.aspx?csf=1&web=1&share=EQfQHQIqSnREj4WCu31XXxIBgh9Ip8_1R6FNGCSAIOo4eg&e=IMd7kw&xsdata=MDV8MDF8fGYzOTc3Yzc0NzNkNzRiYWU1ODE1MDhkYmVkMDVhNTcxfGZjYjJiMzdiNWRhMDQ2NmI5YjgzMDAxNGI2N2E3Yzc4fDB8MHw2MzgzNjQzODA3MDkzNDUzMDF8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPakU1TlROalpqWm1MVEF3WTJVdE5HVXhaUzFpWkRoa0xUTTVPVGt5TW1KbE1URXlPRjgwWVRVMU1UTmhaQzAwTURZNUxUUmhZVFF0T0dZd1lTMWxOR0V4WWpkbU1EY3hNVGxBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN3TURnME1USTNNRFUxT0E9PXwxNjAxZDhkZjZkNDE0Y2Q0NTgxNTA4ZGJlZDA1YTU3MXw3OWRhZTkwMDc5NTg0ZmE3YTE2YzcyMGRiZTk3OGY0Mw%3D%3D&sdata=S2MxM3VBZjdWaVRxc0Fkb2RSa0dHemM0YXFET0xmeE5KMDhPaU1HSWxrbz0%3D&ovuser=fcb2b37b-5da0-466b-9b83-0014b67a7c78%2Cjuanesteban.mosso%40bayer.com&OR=Teams-HL&CT=1700841385092&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA5MjkxMTIwOCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&cid=fa9e853e-fd7a-4f6b-bbaa-0fd83f9d0bae",
      "_blank"
    );
  };
  return (
    <>
      <div
        className={`${classes["section-container"]} ${
          containerIsVisible && classes.visible
        }`}
      >
        {title}
      </div>

      <div
        ref={containerRef}
        className={`${classes["outer-card-container"]} ${
          links.length < 4 && classes["no-scroll"]
        } ${containerIsVisible && classes.visible}`}
      >
        <div
          className={classes["image-container"]}
          onClick={openLinkInNewWindow}
        >
          <img src="images/banners/banner-equipo.png" alt="community banner" />
        </div>
      </div>
    </>
  );
}

export default AlternativeCardsContainer;
